import { Project, Table, trpc } from "utils/trpc";
import { createContext, useContext, useMemo } from "react";
import { suspensify } from "lib/utils/suspensify";

function useTablesResource(initialData?: Table[]) {
  const { refetch } = trpc.tableTables.list.useQuery(undefined, {
    enabled: false,
    keepPreviousData: true,
    initialData: initialData ? { data: initialData, meta: {} } : undefined,
  });

  return useMemo(
    () =>
      suspensify(
        new Promise<Table[]>((resolve) => {
          if (initialData !== undefined) {
            resolve(initialData);
          } else {
            void refetch()
              .then((result) => (result.isSuccess ? result.data.data : []))
              .then(resolve);
          }
        })
      ),
    [initialData, refetch]
  );
}

const TablesContext = createContext<ReturnType<typeof useTablesResource>>({
  read() {
    throw new Promise(() => {
      // infinite suspense
    });
  },
});

export function TablesProvider({
  children,
  initialData,
}: {
  children: React.ReactNode;
  accountId: Project["accountId"];
  initialData?: Table[];
}) {
  const tablesResource = useTablesResource(initialData);

  return (
    <TablesContext.Provider value={tablesResource}>
      {children}
    </TablesContext.Provider>
  );
}

export function useTables() {
  const tablesResource = useContext(TablesContext);

  return tablesResource.read();
}
